import fetch from "./fetch";

const MMB02_URL = () => "/PC/WPC_MMB02";
const MMB02_01_URL = () => "/PC/WPC_MMB02_01";
const MMB02_02_URL = () => "/PC/WPC_MMB02_02";

export default {
  getMmb02: fetch("GET", MMB02_URL),
  getMmb0201: fetch("GET", MMB02_01_URL),
  getMmb0202: fetch("GET", MMB02_02_URL),
};
