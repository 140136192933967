<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-close">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10072')}}
							</h2>
							<p class="inner__text">
								{{t('10073')}}
							</p>
						</div>
						<div class="inner__wrap">
							<!-- style -->
							<div class="style">
								<!-- 썸네일형ㅌ -->
								<ul class="style__thumblist" style="margin-top:-20px">
									<li v-for="location in mhm0103.slice(0,9)" :key="location.LOC_NO">
										<label>
											<input type="checkbox" class="chk blind" :value="location.LOC_NO" v-model="checkedList" @click="handleClick">
											<span class="style__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + location.IMG_URL + '\')'}" />
												<i class="icon icon-complete"></i>
											</span>
											<span>
												{{location.LOC_NM}}
											</span>
										</label>
									</li>
								</ul>
								<!-- 목록형 -->
								<ul class="tag__list tag__list--third">
									<li v-for="location in mhm0103.slice(9)" :key="location.LOC_NO" >
										<label>
											<input type="checkbox" class="chk blind" :value="location.LOC_NO" v-model="checkedList" @click="handleClick">
											<span class="tag__text">
												{{location.LOC_NM}}
											</span>
										</label>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<button type="button" class="btn" @click="handleSubmit()" :class="checkedList.length === 0 ? 'btn-disabled' : 'btn-submit'">모두 골랐어요</button><!-- 재번역 체크 -->
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>

import { computed, onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import api from "@/urls/mmb02"
import { useRouter } from 'vue-router'
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const store = useStore();
		const userData = computed(() => store.state.userData);
		const mhm0103 = computed(() => store.state.mhm01.mhm0103);
		const checkedList = ref([]);
		const router = useRouter();
		const openErrorAlert = ref(false)
		onMounted(() => {store.dispatch("mhm01/fetchMhm0103", {proc_cd: "02"});
			api.getMmb02({mem_id: userData.value.mem_token, proc_cd: "11"}).then((data) => {
				checkedList.value = data.data.Res_data.map(a => a.LIKING_CD);
			});
		});

		const handleClick = (e) => {
			if(e.target.checked && checkedList.value.length >= 3) {
				e.preventDefault();
			}
		}
	
		const handleSubmit = async () => {
			const data = await api.getMmb0202({mem_id: userData.value.mem_token, area_nos: checkedList.value.join(",")});
			if(data.data.Res_cd === "0000") {
				router.push("/favorite/step2");
			}else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: data.data.Res_msg});
				openErrorAlert.value = true;
				//alert(data.data.Res_msg);
			}
		}

		const goBack = () => {
			router.back();
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			mhm0103,
			checkedList,
			handleClick,
			handleSubmit,
			goBack,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,  //번역필수 모듈
		  i18n,
		}
	},
}
</script>